

@font-face {
    font-family: geomanist;
    src: url('../fonts/Geomanist-Bold.ttf');
}

@font-face {
    font-family: gothic;
    src: url('../fonts/GOTHIC.TTF');
    font-weight: normal;
}

@font-face {
    font-family: gothic;
    src: url('../fonts/GOTHICB.TTF');
    font-weight: bold;
}

@font-face {
    font-family: karla;
    src: url('../fonts/Karla-Regular.ttf');
}
