.star-rating{
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    cursor: pointer;
    font-size: 40px;
  }
  .on {
    color: #20a8d8;
  }
  .off {
    color: #ccc;
  }

  .tags{
    color: #20a8d8;
    font-size: 11px;
    font-weight: bold;
  }